import TickImg from '../../assets/car-insurance/main-page/tick-new.svg'
import CrossImg from '../../assets/car-insurance/main-page/cross-new.svg'
import ArrowPriceImg from '../../assets/car-insurance/main-page/price-arrow.svg'
import { ArrowPriceIcon, CardContainer, CrossIcon, MainDiv, TickIcon, PopupHeader, PopupMessage, PopupButton, PopupContainer, ButtonContainer } from "./styles"
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { motorEnquiryAPI ,updateCombinedModel,selectedModal} from "../../Redux/CarsRedux";
import { useEffect, useState } from 'react';
import './gridCss.css';
export const PlanCard = ({item, index, view,setDetailsInfo,setAddons,responseData}) => {
    const navigate = useNavigate();
    var combinedRedux = useSelector((state) => state.carsRedux.combinedRedux);
    var carRedux = useSelector((state) => state.carsRedux);
    const dispatch = useDispatch();
    const [GST, setGST] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const motorQuote = useSelector((state) => state.carsRedux.isFetching);
    const [activeAddons, setActiveAddons] = useState(0);
    const [showPOP, setShowPOP] = useState(false);

    function isRegDateFourYearsOld(regDateString) {
        const regDate = new Date(regDateString);
        const currentDate = new Date();
        
        // Calculate the difference in years
        const yearsDifference = currentDate.getFullYear() - regDate.getFullYear();
        
        // Check if the difference is more than 4 years
        if (yearsDifference > 4) {
          return true;
        } else if (yearsDifference < 4) {
          return false;
        } else {
          // If the difference is exactly 4 years, check months and days
          const currentMonth = currentDate.getMonth();
          const regMonth = regDate.getMonth();
          
          if (currentMonth > regMonth) {
            return true;
          } else if (currentMonth < regMonth) {
            return false;
          } else {
            // If the month is the same, compare the day of the month
            return currentDate.getDate() > regDate.getDate();
          }
        }
      }
      
      const WarningPopup = () => {
        const closePopup = () => {
            // Implement your logic to close the popup
            setShowPOP(false)
            console.log('Popup closed');
        };
        const removeCPA = () => {
            var enquiryId = JSON.parse(localStorage.getItem('enquiryId'));
            console.log('Popup closed');
       let item1={...item,reqBody:{...item.reqBody,Policy_Details:{...item.reqBody.Policy_Details,PreviousPolicy_IsZeroDept_Cover:true,PreviousPolicy_IsRTI_Cover:true}}};
       if(localStorage.getItem('Authorization'))
            navigate('/car-insurance/payment/3?enquiryId='+enquiryId.enquiryId,{state: item1,totalPrice:item?.price});
        else{
            navigate('/signin-signup',{ state: { item:item1} })
        }
            setShowPOP(false)
        }

        return (
            <PopupContainer>
                {/* <CloseIcon src={CloseImg} /> */}
                <PopupHeader>Warning!</PopupHeader>
                <PopupMessage>Do you have Zero Depreciation ro RTI Cover in previous policy? If No inspection is required.</PopupMessage>
                <ButtonContainer>
                    <PopupButton onClick={closePopup}>No</PopupButton>
                    <PopupButton onClick={removeCPA}>Yes</PopupButton>
                </ButtonContainer>
            </PopupContainer>
        );
    };
      
    
    useEffect(() => {
        var cal = ((18/ 100) * item?.price)
        cal = Math.round(cal)
        setGST(cal)
        setTotalPrice(parseInt(item?.price) - parseInt(GST))
    })

    // useEffect(() => {
    //     if(combinedRedux.hasOwnProperty('price')){
    //         var enquiryId = JSON.parse(localStorage.getItem('enquiryId'));
    //         navigate('/car-insurance/payment/3?enquiryId='+enquiryId.enquiryId,{state: item,totalPrice:item?.price}  );
    //     }
    // }, [carRedux])
    useEffect(() => {
        const results = item?.addons?.filter(element => {
            return element.status == true && element !== undefined;
          });
          setActiveAddons(results?.length);
    }, [item?.addons])
    
    if(view==='list') {
        return(
            <MainDiv>
             {showPOP && <WarningPopup />}
                <div className="logo-container">
                    <img src={item?.sluginfo?.logo} alt='logo' />
                    <p>{item?.name}</p>
                </div>
                <hr />
                <div>
                    <p className="uppercase u-l head">tenure</p>
                    <p>{item?.tenure}</p>
                </div>
                <div>
                    <p className="uppercase u-l head">Policy Type</p>
                    <p>{item?.policyType}</p>
                </div>
                <hr />
                <div>
                    <p className="uppercase u-l head">cover value(idv)</p>
                    <p className="bold">&#8377; {item?.idvCoverValue}</p>
                   {item?.minIdv && <p className='head'>Min Idv: ₹{item?.minIdv}</p> }
                   {item?.minIdv && <p className='head'>Max Idv: ₹{item?.maxIdv}</p> }
                </div>
                
                <div className="text-center">
                    <p className="uppercase u-l head">{activeAddons} addons available</p>
                    <div className="flex">
                        <div className='container1'>
                                {
                                    item?.addons?.length > 0 && item?.addons?.map((item,index)=>(
                                        <div className="mr-1">
                                            <div className="flex mb-1">
                                                {
                                                    item.status ?
                                                    <TickIcon src={TickImg} className="mr-1" color="#3AD42F" />
                                                    :
                                                    <CrossIcon src={CrossImg} className="mr-1" color="#F6931E" />
                                                }
                                                <p>{item.name}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                        </div>
                        {/* {
                            item?.addons?.map((item,index)=>(
                                <div>
                                        <>
                                            {index % 6 == 0 ?
                                            <div className="mr-1">
                                                 <div className="flex mb-1">
                                                    <TickIcon src={TickImg} className="mr-1" color="#3AD42F" />
                                                    <p>{item.name}</p>
                                                </div>
                                            </div>
                                            :
                                            <div className="flex mb-1">
                                                    <TickIcon src={TickImg} className="mr-1" color="#3AD42F" />
                                                    <p>{item.name}</p>
                                                </div>
                                            }
                                        </>
                                </div>
                            ))
                        } */}
                        {/* {
                            item?.addons?.map((item,index)=>(
                                <>
                                    {
                                        index % 3 == 0 &&
                                            <div className="mr-1">
                                                <div className="flex mb-1">
                                                    <TickIcon src={TickImg} className="mr-1" color="#3AD42F" />
                                                    <p>Zero Depreciation Cover</p>
                                                </div>
                                                <div className="flex mb-1">
                                                    <TickIcon src={TickImg} className="mr-1" color="#3AD42F" />
                                                    <p>Road Assistance 24/7</p>
                                                </div>
                                                <div className="flex">
                                                    <CrossIcon src={CrossImg} className="mr-1" color="#F6931E" />
                                                    <p>Consumables</p>
                                                </div>
                                            </div>
                                    }
                                </>
                            ))
                        } */}
                        {/* <div className="mr-1">
                            <div className="flex mb-1">
                                <TickIcon src={TickImg} className="mr-1" color="#3AD42F" />
                                <p>Zero Depreciation Cover</p>
                            </div>
                            <div className="flex mb-1">
                                <TickIcon src={TickImg} className="mr-1" color="#3AD42F" />
                                <p>Road Assistance 24/7</p>
                            </div>
                            <div className="flex">
                                <CrossIcon src={CrossImg} className="mr-1" color="#F6931E" />
                                <p>Consumables</p>
                            </div>
                        </div>
                        <div>
                            <div className="flex mb-1">
                                <TickIcon src={TickImg} className="mr-1" color="#3AD42F" />
                                <p>Zero Depreciation Cover</p>
                            </div>
                            <div className="flex mb-1">
                                <TickIcon src={TickImg} className="mr-1" color="#3AD42F" />
                                <p>Road Assistance 24/7</p>
                            </div>
                            <div className="flex">
                                <CrossIcon src={CrossImg} className="mr-1" color="#F6931E" />
                                <p>Consumables</p>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="btn-container no-flex">
                    <button onClick={()=>
                        // navigate('/car-insurance/view-details/1',{ state: { item:item } })
                        {setDetailsInfo(index);
                        setAddons(item.addons);}
                        } className="plan-details">Plan Details</button>
                    <button onClick={()=>
                        {
                            // navigate((localStorage.getItem('Authorization')?'/car-insurance/payment/3':'/car-insurance/payment/1'),{ state: { item:item }})
                            if(localStorage.getItem('Authorization')){
                                var enquiryId = JSON.parse(localStorage.getItem('enquiryId'));
                                // dispatch(updateCombinedModel({price:item?.price,quickQuote:item}));
                                const isage4Yrs = isRegDateFourYearsOld(combinedRedux?.regDate);
                                console.log("data",isage4Yrs,combinedRedux?.regDate)
                                if(item?.slug=="motor-hdfc" && item?.isZDWarning){
                                 setShowPOP(true);
                                }else
                                navigate('/car-insurance/payment/3?enquiryId='+enquiryId.enquiryId,{state: item,totalPrice:item?.price});
                                // setTimeout(() => {
                                //     // navigate('/car-insurance/payment/3?enquiryId='+enquiryId.enquiryId,{state: item,totalPrice:item?.price}  );
                                // }, 1);
                                // return;
                            }
                            else{
                                const isage4Yrs = isRegDateFourYearsOld(combinedRedux?.regDate);
                                console.log("data",isage4Yrs,combinedRedux?.regDate)
                                if(item?.slug=="motor-hdfc" && item?.isZDWarning){
                                 setShowPOP(true);
                                }else
                                navigate('/signin-signup',{ state: { item:item} })
                                // navigate('/car-insurance/payment/1',{ state: { item:item } })
                            }
                        }
                    } className="price-btn">₹ {Math.ceil(item?.price)}</button>
                </div>
            </MainDiv>
        )
    } else if(view==='grid') {
        return(
            <CardContainer>
             {showPOP && <WarningPopup />}
               <img src={item?.sluginfo?.logo} alt='logo' />
                <p className="brand">{item.name}</p>
                <div className="flex apart">
                    <p className="fs-13">Tenure: {item.tenure}</p>
                    <p className="fs-13">IDV : <span className="bold">₹ {item?.idvCoverValue}</span>
                    {item?.minIdv && <p className='head'>Min Idv: ₹{item?.minIdv}</p> }
                   {item?.minIdv && <p className='head'>Max Idv: ₹{item?.maxIdv}</p> }
                    </p>
                    <div>
                    <p className="">Policy Type</p>
                    <p>{item.policyType}</p>
                </div>
                </div>
                <p className="fs-13">{activeAddons} addons available</p>

                <div className='container1'>
                                {
                                    item?.addons?.length > 0 && item?.addons?.map((item,index)=>(
                                        <div className="mr-1">
                                        <div className="flex mb-1">
                                            {
                                                item.status ?
                                                <TickIcon src={TickImg} className="mr-1" color="#3AD42F" />
                                                :
                                                <CrossIcon src={CrossImg} className="mr-1" color="#F6931E" />
                                            }
                                            <p>{item.name}</p>
                                        </div>
                            </div>
                                    ))
                                }
                        </div>
{/* 
                <div className="flex apart">
                    <div className="left">
                        <div className="flex mt-half mr-half">
                            <TickIcon src={TickImg} />
                            <p className="fs-11">Zero Depreciation Cover</p>
                        </div>
                        <div className="flex mt-half mr-half">
                            <CrossIcon src={CrossImg} />
                            <p className="fs-11">Consumables</p>
                        </div>
                        <div className="flex mt-half mr-half">
                            <TickIcon src={TickImg} />
                            <p className="fs-11">Zero Depreciation Cover</p>
                        </div>
                        <div className="flex mt-half mr-half">
                            <CrossIcon src={CrossImg} />
                            <p className="fs-11">Consumables</p>
                        </div>
                        <div className="flex mt-half mr-half">
                            <TickIcon src={TickImg} />
                            <p className="fs-11">Zero Depreciation Cover</p>
                        </div>
                    </div>
                    <div className="right">
                        <div className="flex mt-half ">
                            <TickIcon src={TickImg} />
                            <p className="fs-11">Roadside Assistance 24/7</p>
                        </div>
                        <div className="flex mt-half ">
                            <TickIcon src={TickImg} />
                            <p className="fs-11">Roadside Assistance 24/7</p>
                        </div>
                        <div className="flex mt-half ">
                            <TickIcon src={TickImg} />
                            <p className="fs-11">Roadside Assistance 24/7</p>
                        </div>
                        <div className="flex mt-half ">
                            <TickIcon src={TickImg} />
                            <p className="fs-11">Roadside Assistance 24/7</p>
                        </div>
                        <div className="flex mt-half ">
                            <TickIcon src={TickImg} />
                            <p className="fs-11">Roadside Assistance 24/7</p>
                        </div>
                    </div>
                </div> */}
                <div className="flex mt-half apart">
                    <button onClick={()=>
                        // navigate('/car-insurance/view-details/1',{ state: { item:item } })
                        {setDetailsInfo(index);
                        setAddons(item.addons);}
                        } className="plan-details">Plan Details</button>
                    <button 
                    // onClick={()=>navigate('/car-insurance/payment/3')}
                    onClick={()=>
                        {
                            if(localStorage.getItem('Authorization')){
                                var enquiryId = JSON.parse(localStorage.getItem('enquiryId'));
                                const isage4Yrs = isRegDateFourYearsOld(combinedRedux?.regDate);
                                console.log("data",isage4Yrs,combinedRedux?.regDate)
                                if(item?.slug=="motor-hdfc" && item?.isZDWarning){
                                 setShowPOP(true);
                                 return;
                                }

                                // dispatch(updateCombinedModel({price:item?.price,quickQuote:item}));
                                // navigate('/car-insurance/payment/3?enquiryId='+enquiryId.enquiryId,{state: item,totalPrice:item?.price}  );
                                
                                navigate('/car-insurance/payment/3?enquiryId='+enquiryId.enquiryId,{state: item,totalPrice:item?.price});
                                // setTimeout(() => {
                                    // navigate('/car-insurance/payment/3?enquiryId='+enquiryId.enquiryId,{state: item,totalPrice:item?.price}  );
                                // }, 1); 
                                // navigate('/car-insurance/payment/3?enquiryId='+enquiryId.enquiryId,{state: item});
                                // dispatch(motorEnquiryAPI({price:totalPrice}));
                                // dispatch(updateCombinedModel({price:item?.price,quickQuote:item?.responseData}));
                                // dispatch(motorEnquiryAPI({price:item?.price,quickQuote:item?.responseData}));
                                return;
                            }
                            else{
                                navigate('/signin-signup',{ state: { item:item } })
                            }
                        }
                    }
                     className="price-btn">₹ {Math.ceil(item?.price)} <ArrowPriceIcon src={ArrowPriceImg} /></button>
                </div>
                
            </CardContainer>
        )
    }
}