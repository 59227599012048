import { FilterBar } from "../../../components/filter-bar/FilterBar";
import {
  GridActiveIcon,
  GridInactiveIcon,
  ListIcon,
  ListInactiveIcon,
  MainContainer,
  Opacity,
  SkeletonContainer,
} from "./styles";
import ListView from "../../../assets/car-insurance/main-page/list.svg";
import GridView from "../../../assets/car-insurance/main-page/grid.svg";
import { PlanCard } from "../../../components/plan-card/PlanCard";
import Brand1 from "../../../assets/car-insurance/form-5/national.png";
import Brand2 from "../../../assets/car-insurance/form-5/icici.png";
import Brand3 from "../../../assets/car-insurance/form-5/reliance.png";
import Brand4 from "../../../assets/car-insurance/form-5/digit.png";
import Footer from "../../../components/Footer/FooterNew";
import { Pagination } from "../../../components/pagination/Pagination";
import ListInactiveImg from "../../../assets/car-insurance/main-page/list-inactive.svg";
import GridActive from "../../../assets/car-insurance/main-page/grid-active.svg";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  GODIGITMotorQuote,
  KotakMotorQuote,
  HDFCMotorQuote,
  getAddons,
  motorEnquiryAPI,
  resetMotorQuoteRedux,
  ICICIMotorQuote,
  RELIANCEMotorQuote
} from "../../../Redux/CarsRedux";
import { SkeletonComponent } from "../../../components/skeleton-component/SkeletonComponent";
// import queryString from 'query-string';
import { useLocation } from "react-router-dom";
import { ViewDetailsPopup } from "../../../components/view-details-popup/ViewDetailsPopup";

export const CarMainPage = () => {
  const location = useLocation();
  const combinedRedux = useSelector((state) => state.carsRedux.combinedRedux);
  console.log("combinedRedux ", combinedRedux);
  const [reduxState, setReduxState] = useState(combinedRedux);
  const motorQuote = useSelector((state) => state.carsRedux.motorQuote);
  const carsRedux = useSelector((state) => state.carsRedux);
  const [detailsInfo, setDetailsInfo] = useState(-1);
  const [resetRedux, setResetRedux] = useState(false);
  const [addons, setAddons] = useState([]);
  const [resetQuotes, setResetQuotes] = useState(false);
  const [updateCover, setUpdateCover] = useState(false);
  const [motorQuoteState, setMotorQuoteState] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [fistCall, setFistCall] = useState(false);
  const [ncbChange, setNcbChange] = useState(false);
  const [sortByIdv, setSortByIdv] = useState(false);

  useEffect(() => {
    // console.log("details44", motorQuote.length, sortByIdv);
    if (motorQuote.length >= 2 && combinedRedux?.newPolicyData?.policyType != "privateCarTP") {
      let arr = [...motorQuote]
      // if (!sortByIdv) {
        let price = []
        arr.forEach((obj) => {
          price.push(obj.price)
        })
        var data = []
        var sorted = price.sort(function (a, b) { return a - b })
        for (let j = 0; j <= sorted.length; j++) {
          for (let i = 0; i < arr.length; i++) {
            if (sorted[j] == arr[i].price) {
              data.push(arr[i]);
              break;
            }
          // }
        }
        setMotorQuoteState(data);
      }
      // else {
      //   const data = arr.sort((a, b) => {
      //     if (a.idvCoverValue === combinedRedux.vehicleIdv) {
      //       return -1; // a comes first
      //     } else if (b.idvCoverValue === combinedRedux.vehicleIdv) {
      //       return 1; // b comes first
      //     } else {
      //       return a.idvCoverValue - b.idvCoverValue; // ascending order based on minIdv
      //     }
      //   });
      //   setMotorQuoteState(data)
      // }
    }

    else {
      setMotorQuoteState([...motorQuote])
    }
  }, [motorQuote]);

  useEffect(() => {
    var nObj = reduxState;
    nObj = removeKey("_id", nObj);
    if (nObj && nObj.hasOwnProperty("enquiryId")) {
      if (motorQuote.length < 1) {
        CallQuoteAPIs();
      }
    } else {
      var canInsuranceDetails = localStorage.getItem("canInsuranceDetails");
      if (canInsuranceDetails) {
        canInsuranceDetails = JSON.parse(canInsuranceDetails);
        setResetRedux(true);
        dispatch(motorEnquiryAPI(canInsuranceDetails));
        const myArray = location.search.split("?enquiryId=", 2);
        localStorage.setItem(
          "enquiryId",
          JSON.stringify({ enquiryId: myArray[1], number: "" })
        );
      } else {
        const myArray = location.search.split("?enquiryId=");
        var obj = { type: "get", enquiryId: myArray[1] };
        dispatch(motorEnquiryAPI(obj)).then((res) => {
          // dispatch(motorEnquiryAPI(res?.payload?.data));
        });
        setFistCall(true);
      }
    }
  }, []);

  useEffect(() => {
    if (fistCall) {
      CallQuoteAPIs();
    }

  }, [combinedRedux]);


  useEffect(() => {
    localStorage.setItem("iHeight", document.body.scrollHeight);
  }, []);

  // useEffect(() => {
  //     if(motorQuote.length == 0) {
  //         var obj2 = {...combinedRedux, ...{
  //             newData:'new'
  //         }};
  //         // dispatch(GODIGITMotorQuote(obj2));
  //         // dispatch(KotakMotorQuote(obj2));
  //     }
  //     // var nArr = motorQuote;
  //     // nArr.sort((a, b) => (a.price) > (b.price) ? 1 : -1)
  //     // .map((item)=>{
  //     //     console.log(item.price);
  //     // })
  // }, [motorQuote])

  const removeKey = (key, object) => {
    const { [key]: gone, ...rest } = object;
    return rest;
  };

  const CallQuoteAPIs = (newData) => {
    setResetRedux(false);
    if (newData == 'new') {
      dispatch(resetMotorQuoteRedux('')).then((res) => {
        dispatch(GODIGITMotorQuote(combinedRedux));
        dispatch(KotakMotorQuote(combinedRedux));
        dispatch(HDFCMotorQuote(combinedRedux));
        dispatch(ICICIMotorQuote(combinedRedux));
        dispatch(RELIANCEMotorQuote(combinedRedux));
      })
      return;
    }
    dispatch(GODIGITMotorQuote(combinedRedux));
    dispatch(KotakMotorQuote(combinedRedux));
    dispatch(HDFCMotorQuote(combinedRedux));
    dispatch(ICICIMotorQuote(combinedRedux));
    dispatch(RELIANCEMotorQuote(combinedRedux));
  };

  useEffect(() => {
    if (motorQuote.length == 0 && resetQuotes) {
      var obj2 = {
        ...combinedRedux,
        ...{
          newData: "new",
        },
      };
      dispatch(GODIGITMotorQuote(obj2));
      dispatch(KotakMotorQuote(obj2));
      dispatch(HDFCMotorQuote(obj2));
      dispatch(ICICIMotorQuote(obj2))
      dispatch(RELIANCEMotorQuote(obj2));
      setResetQuotes(false);
    }
  }, [motorQuote]);

  useEffect(() => {

    if (combinedRedux && resetRedux) {
      CallQuoteAPIs();
      setResetRedux(false);
    }
  }, [combinedRedux]);


  const [moreFilters, setMoreFilters] = useState(false);
  const [selectedView, setSelectedView] = useState("list");
  const [windowSize, setWindowSize] = useState();

  const handleWindowResize = useCallback((event) => {
    setWindowSize(window.innerWidth);
  }, []);

  useEffect(() => {
    if (motorQuote?.length) {
      // motorQuote?.length
      setLoading(false);
    }
  }, [motorQuote]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  useEffect(() => {
    if (windowSize < 1290 || window.innerWidth < 1290) {
      setSelectedView("grid");
    }
  }, [windowSize]);

  const ViewBar = ({ currentPlan, totalPlans, activeView }) => {
    return (
      <div className="h-div-apart">
        <p>
          Showing {motorQuote?.length} of {motorQuote?.length} Plans
        </p>
        <div className="h-div">
          <p>View :</p>
          {selectedView === "list" ? (
            <ListIcon src={ListView} />
          ) : (
            <ListInactiveIcon
              onClick={() => setSelectedView("list")}
              src={ListInactiveImg}
            />
          )}
          {selectedView === "grid" ? (
            <GridActiveIcon src={GridActive} />
          ) : (
            <GridInactiveIcon
              onClick={() => setSelectedView("grid")}
              src={GridView}
            />
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      <MainContainer>
        <FilterBar
          loading={loading}
          setLoading={setLoading}
          setSortByIdv={setSortByIdv}
          more={moreFilters}
          onShowMore={setMoreFilters}
          CallQuoteAPIs={CallQuoteAPIs}
          updateCover={updateCover}
        />
        {carsRedux.isFetching || loading ? (
          <SkeletonContainer>
            <SkeletonComponent />
            <SkeletonComponent />
            <SkeletonComponent />
            <SkeletonComponent />
            <SkeletonComponent />
            <SkeletonComponent />
            <SkeletonComponent />
          </SkeletonContainer>
        ) : (
          <>
            <ViewBar
              activeView={"list"}
              currentPlan={15}
              totalPlans={450}
              className="viewbar"
            />

            {selectedView === "list" ? (
              // [...motorQuote]
              // .sort((a, b) => (a.price) < (b.price) ? 1 : -1)
              motorQuoteState &&
              motorQuoteState.length > 0 &&
              motorQuoteState.map((item, index) => {
                if (item != null) {
                  return (
                    <PlanCard
                      index={index}
                      view={selectedView}
                      key={item.id}
                      item={item}
                      responseData={item.responseData}
                      setDetailsInfo={setDetailsInfo}
                      setAddons={setAddons}
                    />
                  );
                }
              })
            ) : (
              <div className="grid gtc-4 mt-1">
                {
                  //  [...motorQuote]
                  // .sort((a, b) => (a.price) < (b.price) ? 1 : -1)
                  motorQuoteState &&
                  motorQuoteState.length > 0 &&
                  motorQuoteState.map((item, index) => {
                    console.log("item3",motorQuoteState[index]);
                    console.log("item31",item)
                    if (item != null) {
                      return (
                        <PlanCard
                          index={index}
                          view={selectedView}
                          key={item.id}
                          item={item}
                          responseData={item.responseData}
                          setDetailsInfo={setDetailsInfo}
                          setAddons={setAddons}
                        />
                      );
                    }
                  })
                }
              </div>
            )}
          </>
        )}
      </MainContainer>
      {/* <Pagination current={1} total={12} /> */}
      <Pagination total={1} currentPage={1} pageSize={10} />
      <Footer />

      <>
        {detailsInfo > -1 && (
          <>
            <Opacity />
            <ViewDetailsPopup
              addons={addons}
              setAddons={setAddons}
              item={detailsInfo}
              slug={motorQuote}
              setDetailsInfo={setDetailsInfo}
              CallQuoteAPIs={CallQuoteAPIs}
              setUpdateCover={setUpdateCover}
            />
          </>
        )}
        {/* <Opacity />
                <ViewDetailsPopup /> */}
      </>
    </>
  );
};
