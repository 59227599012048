  import React, { useState } from 'react';
  import CustomerInfoSection from '../../components/saibaSection/CustomerInfoSection/CustomerInfoSection';
  import PolicyDetailsSection from '../../components/saibaSection/PolicyDetailsSection/PolicyDetailsSection';
  import './PolicyForm.css';
  import PolicyParticularDetails from '../../components/saibaSection/PolicyParticularDetails/PolicyParticularDetails';
  import PaymentModeSection from '../../components/saibaSection/paymentModeSection/PaymentModeSection';
  import ServicingDetailsSection from '../../components/saibaSection/ServicingDetails/servicingDetails';
  import BrokerageDetailsSection from '../../components/saibaSection/BrokerDetailsSection/brokrageDetailsSection';
  import PartnerBrokerSection from '../../components/saibaSection/PartnerBrokerSection/PartnerBrokerSection';
  import CoBrokerSection from '../../components/saibaSection/CoBrokerSection/CoBrockerSection';
  import CampaignPolicyStatusSection from '../../components/saibaSection/CampaignPolicyStatusSection/CampaignPolicyStatusSection';
  import MotorPolicyDetails from '../../components/saibaSection/motorPolicyDetails/MotorPolicyDetails';
  import axios from 'axios';
  import { useNavigate } from 'react-router-dom';

  const PolicyForm = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
      controlNo: '',
      enquiryCode: '',
      custCode: '',
      customerName: '',
      insuredName: '',
      nomineeDetails: '',
      nomineeRelation: '',
      insurerName: '',
      insurerCity: 'ALL',
      insurerBranch: '',
      nomineeDetails:"",
      nomineeRelation:"",

      // part II
        insurerName: '',
    insurerCity: '',
    insurerBranch: '',
    busBookDate: '',
    startDate: '',
    department: '',
    days: '',
    expiryDate: '',
    policyType: '',
    isRenewable: 'Yes',
    policyNo: '',
    hypothecation: 'No',
    projectName: '',
    policyExcess: '',
    insuredLocation: '',
    policyRemarks: '',

      //  part III
      sNo: 1,
      coverage: '',
      extensions: '',
      sumInsured: 0,
      premRate: 0,
      netOdPrem: 0,
      locationRemarks: '',
      gstTax: 0.0,

      // part IV
      // Payment details
      paymentMode: '',
      amount: '',
      bank: '',
      chequeNumber: '',
      dated: '',
      bankBranch: '',
      payModeRemark: '',

      // part V
      posMispRef: 'Ref',
      posMispRefCode: '',
      posMispRefName: '',
      cscPeCode: '',
      cscPeName: '',
      rmCe1Code: '',
      rmCe1Name: '',
      solicitorCe2Code: '',
      solicitorCe2Name: '',
      tcCode: '',
      tcName: '',

      // part VI
      brokerageType: 'Brokerage',
      brokerageRemarks: '',
      gstSTax: 18.0,
      brokerageRate: '',
      brokerageAmount: 0,
      tpBrokerageRate: 0,
      tpBrokerageAmount: 0,
      rewardOn: 'Brokerage',
      rewardAmount: 0,
      rewardPercent: 0,
      tpRewardPercent: 0,
      tpAmount: 0,
      exp: 0,
      expTP: 0,
      refODPrem: 0,
      refTP: 0,
      csc: 0,
      refPosAt: 'NP',

      // part VII
      partnerBrokerName: '',
      partnerBrokerCode: '',
      network: '',
      crossReferral: '',

      // part VIII
      coBrokerName: '',
      coBrokerBranchCode: '',
      coBrokerShare: '',

      // part IX
      campaignName: '',
      policyStatus: 'Logged in',
      policyStatusRemarks: '',

      // Motor Policy Details
      cvrNoteDate: '',
      mtrPolicyStatus: '',
      regNo: '',
      typeOfVehicle: '',
      category: '',
      noOfPassenger: '',
      engineNo: '',
      vehicleClass: '',
      make: '',
      model: '',
      variant: '',
      chasisNo: '',
      cc: '',
      yearOfManufacturing: '',
      dateOfReg: '',
      invoiceDate: '',
      seats: '',
      fuel: '',
      rtoState: '',
      rto: '',
      zone: '',
      ncb: '',
      odd: '',
      wheels: '',
      color: '',
      inspectionAgency: '',
      roadType: '',
      transmissionType: '',
      withoutNilDep: '',
    });

    const [addedParticularDetails, setAddedParticularDetails] = useState([]);
    const [coBrokers, setCoBrokers] = useState([]);
    const [isCoBrokerChecked, setIsCoBrokerChecked] = useState(false);
    const [isCheckedPartnerBroker, setIsCheckedPartnerBroker] = useState(false);

    const handleAddParticularDetail = (newData) => {
      setAddedParticularDetails([...addedParticularDetails, newData]);
    };

    const handleDeleteParticularDetail = (indexToDelete) => {
      const filteredData = addedParticularDetails.filter(
        (_, index) => index !== indexToDelete
      );
      setAddedParticularDetails(filteredData);
    };

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };

const handleSubmit = async (e) => {
  e.preventDefault();

  console.log('Submitting form datatatat...', formData);

  // Create a fullFormData object matching the schema
  const fullFormData = {
    controlNo: formData.controlNo,
    enquiryCode: formData.enquiryCode,
    custCode: formData.custCode,
    customerName: formData.customerName,
    insuredName: formData.insuredName,
    nomineeDetails: formData.nomineeDetails,
    nomineeRelation: formData.nomineeRelation,
    insurerName: formData.insurerName,
    insurerCity: formData.insurerCity,
    insurerBranch: formData.insurerBranch,

    policyDetails: {
      busBookDate: formData.busBookDate,
      startDate: formData.startDate,
      department: formData.department,
      days: formData.days,
      expiryDate: formData.expiryDate,
      policyType: formData.policyType,
      isRenewable: formData.isRenewable,
      policyNo: formData.policyNo,
      hypothecation: formData.hypothecation,
      projectName: formData.projectName,
      policyExcess: formData.policyExcess,
      insuredLocation: formData.insuredLocation,
      policyRemarks: formData.policyRemarks,
    },

    paymentDetails: {
      paymentMode: formData.paymentMode,
      amount: formData.amount,
      bank: formData.bank,
      chequeNumber: formData.chequeNumber,
      dated: formData.dated,
      bankBranch: formData.bankBranch,
      payModeRemark: formData.payModeRemark,
    },

    brokerageDetails: {
      brokerageType: formData.brokerageType,
      brokerageRemarks: formData.brokerageRemarks,
      gstSTax: formData.gstSTax,
      brokerageRate: formData.brokerageRate,
      brokerageAmount: formData.brokerageAmount,
      tpBrokerageRate: formData.tpBrokerageRate,
      tpBrokerageAmount: formData.tpBrokerageAmount,
      rewardOn: formData.rewardOn,
      rewardAmount: formData.rewardAmount,
      rewardPercent: formData.rewardPercent,
      tpRewardPercent: formData.tpRewardPercent,
      tpAmount: formData.tpAmount,
    },

    partnerBrokerDetails: {
      partnerBrokerName: formData?.partnerBrokerName,
      partnerBrokerCode: formData?.partnerBrokerCode,
      network: formData?.network,
      crossReferral: formData?.crossReferral,
    },

    coBrokers: coBrokers?.map((broker) => ({
      coBrokerName: broker?.coBrokerName,
      coBrokerBranchCode: broker.coBrokerName === 'self' ? 'NA' : "NA", 
      coBrokerShare: broker?.coBrokerShare,
    })),

    campaigningDetails: {
      campaignName: formData.campaignName,
      policyStatus: formData.policyStatus,
      policyStatusRemarks: formData.policyStatusRemarks,
    },

    motorPolicyDetails: {
      cvrNoteDate: formData.cvrNoteDate,
      mtrPolicyStatus: formData.mtrPolicyStatus,
      regNo: formData.regNo,
      typeOfVehicle: formData.typeOfVehicle,
      category: formData.category,
      noOfPassenger: formData.noOfPassenger,
      engineNo: formData.engineNo,
      vehicleClass: formData.vehicleClass,
      make: formData.make,
      model: formData.model,
      variant: formData.variant,
      chasisNo: formData.chasisNo,
      cc: formData.cc,
      yearOfManufacturing: formData.yearOfManufacturing,
      dateOfReg: formData.dateOfReg,
      invoiceDate: formData.invoiceDate,
      seats: formData.seats,
      fuel: formData.fuel,
      rtoState: formData.rtoState,
      rto: formData.rto,
      zone: formData.zone,
      ncb: formData.ncb,
      odd: formData.odd,
      wheels: formData.wheels,
      color: formData.color,
      inspectionAgency: formData.inspectionAgency,
      roadType: formData.roadType,
      transmissionType: formData.transmissionType,
      withoutNilDep: formData.withoutNilDep,
    },

    addedParticularDetails: addedParticularDetails.map((detail) => ({
      sNo: detail.sNo,
      coverage: detail.coverage,
      extensions: detail.extensions,
      sumInsured: detail.sumInsured,
      premRate: detail.premRate,
      netOdPrem: detail.netOdPrem,
      locationRemarks: detail.locationRemarks,
      gstTax: detail.gstTax,
    })),
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}new-policy`,
      fullFormData
    );

    if (response.data) {
      navigate(`/policyUpload/${response.data._id}`);
    }
    console.log('Form successfully submitted', response.data);
  } catch (error) {
    console.error('Error submitting form', error);
    // Show an error message to the user
  }
};

    // for Partner Broker Section
    const handleCheckboxChangePartnerBroker = () => {
      setIsCheckedPartnerBroker(!isCheckedPartnerBroker);
    };

    // for co Workers
    const handleCheckboxChange = () => {
      setIsCoBrokerChecked(!isCoBrokerChecked);
    };

    const handleAddCoBroker = () => {
      setCoBrokers([
        ...coBrokers,
        {
          coBrokerName: formData.coBrokerName,
          coBrokerBranchCode: formData.coBrokerBranchCode,
          coBrokerShare: formData.coBrokerShare,
          sno: coBrokers.length + 1,
        },
      ]);

      // Reset the Co-Broker fields in the form
      setFormData({
        ...formData,
        coBrokerName: '',
        coBrokerBranchCode: '',
        coBrokerShare: '',
      });
    };

    const handleRemoveCoBroker = (indexToDelete) => {
      setCoBrokers(coBrokers.filter((_, index) => index !== indexToDelete));
    };

      const handleExit = () => {
        setFormData({}); // Clears the form
        console.log('Exiting form...');
      };

    return (
      <div>
        <form onSubmit={handleSubmit} className="policy-form">
          <p className="brokerPolicyHeading">Non-Life Standard Policy Entry </p>
          <CustomerInfoSection formData={formData} handleChange={handleChange} />
          <PolicyDetailsSection formData={formData} handleChange={handleChange} />
          
          {formData.department === 'motor' &&
          formData.policyType === 'motorType' ? (
            <MotorPolicyDetails formData={formData} handleChange={handleChange} />
          ) : null}
          {/* Policy Particular Details Section */}
          <PolicyParticularDetails
            formData={formData}
            handleAddData={handleAddParticularDetail}
            addedData={addedParticularDetails}
            handleDelete={handleDeleteParticularDetail}
          />

          <PaymentModeSection formData={formData} handleChange={handleChange} />
          <ServicingDetailsSection
            formData={formData}
            handleChange={handleChange}
          />
          <BrokerageDetailsSection
            formData={formData}
            handleChange={handleChange}
          />
          <PartnerBrokerSection
            formData={formData}
            handleChange={handleChange}
            handleCheckboxChange={handleCheckboxChangePartnerBroker}
            isChecked={isCheckedPartnerBroker}
          />
          <CoBrokerSection
            formData={formData}
            handleChange={handleChange}
            handleAddCoBroker={handleAddCoBroker}
            handleRemoveCoBroker={handleRemoveCoBroker}
            coBrokers={coBrokers}
            handleCheckboxChange={handleCheckboxChange}
            isChecked={isCoBrokerChecked}
          />
          <CampaignPolicyStatusSection
            formData={formData}
            handleChange={handleChange}
          />

          <div className="saveExitBtnGrp">
            <button type="submit" className="submit-btn-policy-broker">
              Save
            </button>
            <button
              type="button"
              onClick={handleExit}
              className="exit-btn-policy-broker"
            >
              Exit
            </button>
          </div>
        </form>
      </div>
    );
  };

  export default PolicyForm;
