import React from 'react';
import './customerInfoSection.css';

const CustomerInfoSection = ({ formData, handleChange }) => {
  return (
    <div className="customer-info-section">
      <h2>Customer Info Section</h2>
      <div className="customer-info-grid">
        <div className="form-group">
          <label htmlFor="controlNo" className="customerInfoSectionLabel">
            Control No.
          </label>
          <input
            type="text"
            className="customerInfoSectionInput"
            id="controlNo"
            name="controlNo"
            value={formData.controlNo}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="enquiryCode" className="customerInfoSectionLabel">
            Enquiry Code
          </label>
          <input
            type="text"
            id="enquiryCode"
            name="enquiryCode"
            className="customerInfoSectionInput"
            value={formData.enquiryCode}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="bizType" className="customerInfoSectionLabel">
            Biz Type
          </label>
          <select
            id="bizType"
            name="bizType"
            className="customerInfoSectionSelect"
            value={formData.bizType}
            onChange={handleChange}
          >
            <option value="New">New</option>
            <option value="Renewal">Renewal</option>
            <option value="expended">expended</option>
            <option value="portability">portability</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="branchCode" className="customerInfoSectionLabel">
            Branch Code
          </label>
          <input
            type="text"
            id="branchCode"
            className="customerInfoSectionInput"
            name="branchCode"
            value={formData.branchCode}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="branchName" className="customerInfoSectionLabel">
            Branch Name
          </label>
          <input
            type="text"
            id="branchName"
            name="branchName"
            className="customerInfoSectionInput"
            value={formData.branchName}
            onChange={handleChange}
          />
        </div>

        <div className="form-group required">
          <label htmlFor="custCode" className="customerInfoSectionLabel">
            Cust Code
          </label>
          <input
            type="text"
            id="custCode"
            className="customerInfoSectionInput"
            name="custCode"
            value={formData.custCode}
            onChange={handleChange}
            required
          />
          <button className="info-btn">?</button>
        </div>

        <div className="form-group">
          <label htmlFor="customerName" className="customerInfoSectionLabel">
            Customer Name
          </label>
          <input
            type="text"
            id="customerName"
            className="customerInfoSectionInput"
            name="customerName"
            value={formData.customerName}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="insuredName" className="customerInfoSectionLabel">
            Insured/Proposer Name
          </label>
          <input
            type="text"
            id="insuredName"
            name="insuredName"
            className="customerInfoSectionInput"
            value={formData.insuredName}
            onChange={handleChange}
          />
          <button className="add-btn">+</button>
        </div>

        <div className="form-group">
          <label htmlFor="custGroup" className="customerInfoSectionLabel">
            Cust Group
          </label>
          <input
            type="text"
            id="custGroup"
            name="custGroup"
            className="customerInfoSectionInput"
            value={formData.custGroup}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="insuredDOB" className="customerInfoSectionLabel">
            Primary Insured DOB
          </label>
          <input
            type="date"
            id="insuredDOB"
            name="insuredDOB"
            className="customerInfoSectionInput"
            value={formData.insuredDOB}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="insuredAge" className="customerInfoSectionLabel">
            Primary Insured Age
          </label>
          <input
            type="text"
            id="insuredAge"
            name="insuredAge"
            className="customerInfoSectionInput"
            value={formData.insuredAge}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="nomineeDetails" className="customerInfoSectionLabel">
            Nominee Details
          </label>
          <input
            type="text"
            id="nomineeDetails"
            name="nomineeDetails"
            className="customerInfoSectionInput"
            value={formData.nomineeDetails}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="nomineeRelation" className="customerInfoSectionLabel">
            Nominee Details
          </label>
          <select
            id="nomineeRelation"
            name="nomineeRelation"
            className="customerInfoSectionSelect"
            value={formData.nomineeRelation}
            onChange={handleChange}
          >
            <option value="">--Select--</option>
            <option value="Brother">Brother</option>
            <option value="Child1">Child 1</option>
            <option value="Child2">Child 2</option>
            <option value="Child3">Child 3</option>
            <option value="Daughter">Daughter</option>
            <option value="Dependant">Dependant</option>
            <option value="Employee">Employee</option>
            <option value="Father">Father</option>
            <option value="FatherInLaw">Father In Law</option>
            <option value="Husband">Husband</option>
            <option value="Mother">Mother</option>
            <option value="MotherInLaw">Mother In Law</option>
            <option value="Self">Self</option>
            <option value="Sister">Sister</option>
            <option value="Son">Son</option>
            <option value="Spouse">Spouse</option>
            <option value="Wife">Wife</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default CustomerInfoSection;
