import React, {useEffect } from 'react'
import { CiImageOn } from 'react-icons/ci'
import { useNavigate } from 'react-router-dom'
import SubmittedGif from '../components/submitted.gif'

import './Pos.css'

const Pos = () => {
  const navigate = useNavigate()
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/review');
    }, 2000); // 2 seconds

    // Clean up the timer when the component is unmounted
    return () => clearTimeout(timer);
  }, [navigate]);
  return (
    <>
      <div className="main7">
        <div className="Pos">
          <div className="Pos1">
            <img src={SubmittedGif} alt="SubmittedGif" />
          </div>
          <div className="Pos2">
            <p>Pos has been successfully added</p>
          </div>
          {/* width="140" height="1395" */}
          {/* decoding="sync"  */}
          <div className="Pos3"></div>
        </div>
      </div>
    </>
  );
}

export default Pos