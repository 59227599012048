import React,{useState} from 'react'
import './Footer.css'
import logo from '../../assets/simpli_logo_icon.svg'
import media from '../../assets/media.png'
import appstore from '../../assets/appstore.png'
import { useNavigate } from 'react-router-dom'
import {Modal} from 'antd'
import { Contact } from '../insurance-contact/Contact';
import { FaXTwitter, FaInstagram, FaLinkedin, FaYoutube, FaArrowRight, FaPhone } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { IoLogoWhatsapp, IoMdMail } from "react-icons/io";
import { newsLatter } from "../../Redux/AuthRedux";
import { useDispatch } from 'react-redux';


const FooterNew = () => {

  const dispatch = useDispatch();
  const [name, setName] = useState("from footer"); 
  const [email, setEmail] = useState("");


	const[show,setShow]=useState(false)
	const navigate = useNavigate()
	const handleModal=()=>{
		setShow(!show)
	  }

    const handleSubmit = async (e) => {
    if (name === "" || email === "") {
      alert("Please fill all the fields");
      return;
    }

    e.preventDefault();
    dispatch(newsLatter({name, email})).then (res => {
      if (res) {
        alert(res.data || "Subscribed successfully");
        setName("");
        setEmail("");
      }
    });
  };

	return (
    <div className="mainContainer">
      <div className="FooterSecOne">
        <div className="secOnePartOne">
          <div className="footerLogo">
            <img
              src={logo}
              alt="footerLogo"
              onClick={() => navigate('/')}
              className="pointer"
            />
          </div>
          <p className="paraOne">
            Simpliinsure.com, an online portal by Virtual Galaxy Insurance
            Brokers Private Limited.
          </p>
          <p className="paraTwo">
            Your trusted partner for comprehensive coverage and hassle-free
            insurance solutions.
          </p>
        </div>
        <div className="secOnePartTwo width20">
          <ul>
            <li className="boldAndSpace">COMPANY</li>
            <li
              className="pointer"
              onClick={() => navigate('/About-Simpli-Insure')}
            >
              About Simpliinsure.com
            </li>
            <li onClick={() => navigate('/Our-Partners')}>Our Partners</li>
            <li onClick={() => navigate('/Products')} className="pointer">
              Products
            </li>
            {/* <li onClick={() => navigate('/learn')} className='pointer'>Media Corner</li> */}
            <li onClick={() => navigate('/Work-With-Us')} className="pointer">
              Work With Us
            </li>
            <li onClick={() => navigate('/Contact-Us')} className="pointer">
              Contact Us
            </li>
          </ul>
        </div>
        <div className="secOnePartThree width20">
          <ul>
            <li className="boldAndSpace">KNOWLEDGE CENTER</li>
            <li onClick={() => navigate('/blog')}>Learn</li>
            <li className="pointer" onClick={() => navigate('/newsletter')}>
              Newsletter
            </li>
            {/* <li>Top Insurance Policies</li> */}
            <li className="pointer" onClick={() => navigate('/career')}>
              Careers
            </li>
            <li className="pointer" onClick={() => navigate('/faq')}>
              FAQs
            </li>
          </ul>
        </div>
        <div className="secOnePartFour width20">
          <ul>
            <li className="boldAndSpace">QUICK LINKS</li>
            {/* <li >Testimonials</li> */}
            <li>Customer Feedback</li>
            {/* <li>Grievances</li> */}
            <li onClick={() => navigate('/privacy-policy')}>Privacy Policy</li>
            <li onClick={() => navigate('/terms')}>Terms & Conditions</li>
            <li onClick={() => navigate('/sitemap')} className="pointer">
              Sitemap
            </li>
          </ul>
        </div>
      </div>
      {/* part II */}
      <div className="FooterSecTwo">
        <div className="secTwoPartOne">
          <div className="PartOne">
            <p className="boldAndSpace">EMAIL</p>
            <a
              href="mailto:info@simpliinsure.com"
              target="_blank"
              rel="noreferrer"
            >
              <p
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '5px',
                  color: '#000000',
                }}
              >
                <IoMdMail size="1.3rem" style={{ marginRight: '5px' }} />
                <span>info@simpliinsure.com</span>
              </p>
            </a>
            <a
              href="mailto:media@simpliinsure.com"
              target="_blank"
              rel="noreferrer"
            >
              <p
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '5px',
                  color: '#000000',
                }}
              >
                <IoMdMail size="1.3rem" style={{ marginRight: '5px' }} />
                <span>media@simpliinsure.com</span>
              </p>
            </a>
          </div>
          <div className="PartTwo">
            <p className="followUs">FOLLOW US</p>
            <div className="social">
              <a
                href="https://www.facebook.com/SimpliInsureOfficial"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook size="1.3rem" color="#000000" />
              </a>
              <a
                href="http://www.instagram.com/simpliinsureofficial/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram
                  size="1.3rem"
                  className="iconSpace"
                  color="#000000"
                />
              </a>
              <a
                href="https://wa.me/+918660925970?text=Hi%20SimpliInsure,%20I%20have%20a%20query%20regarding%20my%20insurance%20policy."
                target="_blank"
                rel="noreferrer"
              >
                <IoLogoWhatsapp
                  size="1.3rem"
                  className="iconSpace"
                  color="#000000"
                />
              </a>
              <a
                href="http://www.twitter.com/SimpliInsure"
                target="_blank"
                rel="noreferrer"
              >
                <FaXTwitter
                  size="1.3rem"
                  className="iconSpace"
                  color="#000000"
                />
              </a>
              <a
                href="http://www.linkedin.com/company/simpliinsure/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedin
                  size="1.3rem"
                  className="iconSpace"
                  color="#000000"
                />
              </a>
              <a
                href="http://www.youtube.com/@SimpliInsureOfficial"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube
                  size="1.3rem"
                  className="iconSpace"
                  color="#000000"
                />
              </a>
            </div>
          </div>
          <div className="PartThree">
            <p className="boldAndSpace">SUBSCRIBE TO NEWSLETTER</p>
            <div className="footer-inputBox">
              <form
                onSubmit={handleSubmit}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <input
                  type="text"
                  placeholder="Enter your email ID"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button type="submit" className="footer-inputButton">
                  <FaArrowRight
                    style={{ verticalAlign: 'middle', marginRight: '3px' }}
                  />
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="secTwoPartTwo width20">
          <ul>
            <li className="boldAndSpace">PRODUCTS</li>
            <li
              className="textDeco bold pointer"
              onClick={() => navigate('/product/Individual-Insurance-Products')}
            >
              INDIVIDUAL
            </li>

            <li
              className="textDeco pointer"
              onClick={() =>
                navigate(
                  '/product/Individual-Insurance-Products/Health-Insurance'
                )
              }
            >
              Health Insurance
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Individual-Insurance-Products/Health-Insurance/Self-Health-InsurancePlan'
                )
              }
            >
              Individual Health Insurance{' '}
            </li>
            <li
              className="marginBottom pointer"
              onClick={() =>
                navigate(
                  '/product/Individual-Insurance-Products/Health-Insurance/Family-Floater-Health-Insurance-Plan'
                )
              }
            >
              Family Floater Health Insurance
            </li>

            <li
              className="textDeco pointer"
              onClick={() =>
                navigate(
                  '/product/Individual-Insurance-Products/Life-Insurance'
                )
              }
            >
              Life Insurance
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Individual-Insurance-Products/Life-Insurance/Term-Life-Insurance'
                )
              }
            >
              Term Life Insurance
            </li>
            <li
              className="marginBottom pointer"
              onClick={() =>
                navigate(
                  '/product/Individual-Insurance-Products/Life-Insurance/Life-Investment-Related-Cover'
                )
              }
            >
              Life Investment-Related Cover
            </li>

            <li
              className="textDeco pointer"
              onClick={() =>
                navigate(
                  '/product/Individual-Insurance-Products/Motor-Insurance'
                )
              }
            >
              Motor Insurance
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Individual-Insurance-Products/Motor-Insurance/Two-Wheelers-Insurance-Plan'
                )
              }
            >
              Bike Insurance Policy
            </li>
            <li
              className="marginBottom pointer"
              onClick={() =>
                navigate(
                  '/product/Individual-Insurance-Products/Motor-Insurance/Private-Car-Insurance-Plan'
                )
              }
            >
              Car Insurance Policy
            </li>

            <li
              className="textDeco pointer"
              onClick={() =>
                navigate(
                  '/product/Individual-Insurance-Products/Property-Insurance'
                )
              }
            >
              Property Insurance
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Individual-Insurance-Products/Property-Insurance/home-insurance'
                )
              }
            >
              Home Insurance
            </li>
          </ul>
        </div>
        <div className="secTwoPartThree width20">
          <ul>
            <li className="boldAndSpace">&nbsp;</li>
            <li
              className="bold textDeco pointer"
              onClick={() => navigate('/product/corporate-insurance')}
            >
              CORPORATE
            </li>
            <li
              className="textDeco pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Group-and-Employee-Welfare'
                )
              }
            >
              Employee and Group Welfare
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Group-and-Employee-Welfare/Group-Term-Insurance'
                )
              }
            >
              Group Term Insurance
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Group-and-Employee-Welfare/Mediclaim'
                )
              }
            >
              Mediclaim Policy
            </li>
            {/* <li className='pointer' onClick={() => navigate('/Corporate-Insurance-Products/Group-and-Employee-Welfare/Group-Mediclaim-Insurance')}>Group Mediclaim Policy (GMC)</li> */}
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Group-and-Employee-Welfare/Group-Personal-Accident'
                )
              }
            >
              Group Personal Accident Insurance (GPA)
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Group-and-Employee-Welfare/Workers-Compensation-Insurance'
                )
              }
            >
              Workers Compensation Insurance
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Group-and-Employee-Welfare/Directors-and-Officers-Liability-Insurance'
                )
              }
            >
              Directors and Officers Liability Insurance
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Group-and-Employee-Welfare/Product-Liability-Insurance'
                )
              }
            >
              Product Liability Insurance
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Group-and-Employee-Welfare/Commercial-General-Liability-Insurance'
                )
              }
            >
              Commercial General Liability Insurance
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Group-and-Employee-Welfare/Professional-Indemnity-Insurance'
                )
              }
            >
              Professional Indemnity Insurance
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Group-and-Employee-Welfare/Public-Liability-Insurance'
                )
              }
            >
              Public Liability Insurance
            </li>
            <li
              className="marginBottom pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Group-and-Employee-Welfare/Small-Industrial-Insurance'
                )
              }
            >
              Small Industries Insurance
            </li>

            <li
              className="textDeco pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Cyber-and-Data-Security'
                )
              }
            >
              Data and Financial Security Insurance
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Cyber-and-Data-Security/Cyber-Attack-Insurance'
                )
              }
            >
              Cyber Security Insurance
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Cyber-and-Data-Security/Bankers-Indemnity-Insurance'
                )
              }
            >
              Bankers Indemnity Insurance
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Cyber-and-Data-Security/Fidelity-Guarantee-Insurance'
                )
              }
            >
              Fidelity Guarantee Insurance (F.G.I)
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Cyber-and-Data-Security/Money-Insurance'
                )
              }
            >
              Money Insurance Policy
            </li>
          </ul>
        </div>
        <div className="secTwoPartFour width20">
          <ul>
            <li className="boldAndSpace">&nbsp;</li>
            <li>&nbsp;</li>
            <li
              className="textDeco pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Asset-Insurance'
                )
              }
            >
              Asset Insurance
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Asset-Insurance/burglary-insurance'
                )
              }
            >
              Burglary Insurance
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Asset-Insurance/Standard-Fire-and-Special-Perils-Insurance'
                )
              }
            >
              Standard Fire and Special Perils Insurance
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Asset-Insurance/Marine-Cargo-Insurance'
                )
              }
            >
              Marine Cargo Insurance
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Asset-Insurance/Contractors-All-Risk-Insurance'
                )
              }
            >
              Contractors' All Risks Insurance (C.A.R)
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Asset-Insurance/Engineers-All-Risk-Insurance'
                )
              }
            >
              Engineering All-Risk Insurance (E.A.R)
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Asset-Insurance/Machinery-Breakdown-Insurance'
                )
              }
            >
              Machinery Breakdown Insurance
            </li>
            <li
              className="pointer"
              onClick={() =>
                navigate(
                  '/product/Corporate-Insurance-Products/Asset-Insurance/Electronic-Equipment-Insurance'
                )
              }
            >
              Electronic Equipment Insurance
            </li>
            {/* <li className='pointer' onClick={() => navigate('/Corporate-Insurance-Products/Asset-Insurance/Contractor-Plant-and-Machinery-Insurance')}>Contractor's Plant & Machinery Insurance</li> */}
          </ul>
        </div>
      </div>
      {/* part III */}
      <div className="FooterSecThree secThreePadding">
        <p className="paraPartFour">
          Insurance Buying Guide | Best Individual Health Insurance Policies |
          Best Individual Life Insurance Policies | Best Individual Bike
          Insurance Policies | Best Individual Car Insurance Policies | Best
          Corporate Insurance Policies
        </p>

        <hr className="narrow-hr" />

        <p className="paraPartFour marginTop">
          Royal Sundaram | SBI General Insurance | Cholamandalam MS General
          Insurance | Future Generali India Insurance Company | Raheja QBE |
          Shriram General Insurance | Liberty General Insurance | Edelweiss
          Insurance | Universal Sompo Insurance | Magma | Max Bupa Health
          Insurance | HDFC ERGO General Insurance | The New India Assurance |
          United India Insurance | Care Health Insurance | Tata AIG General
          Insurance | Go Digit | Kotak Mahindra General Insurance | ICICI
          Lombard | Bajaj Allianz General Insurance | Cigna Health Insurance |
          IFFCO Tokio General Insurance | Oriental Insurance | Star Health |
          Reliance Insurance | Aditya Birla Health Insurance Co. Limited
          (ABHICL)
        </p>
        <hr className="narrow-hr" />
        <div className="contactData">
          <p>
            <span className="bold">Corporate Office (Registered Address):</span>
            <a
              href="https://maps.app.goo.gl/xHdMVN7wd2YaxeVn6"
              target="_blank"
              rel="noreferrer"
            >
              Simpliinsure.com (A VGIB Brand): No. 91/1, 1st Floor, Above ICICI
              Bank, Infantry Road, Bangalore - 560001, Karnataka, India
            </a>
          </p>
          <p
            style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}
          >
            <FaPhone size="1rem" style={{ marginRight: '5px' }} />
            <span>
              <a href="tel:+919513355661" target="_blank" rel="noreferrer">
                +91 95133 55661, 62, 63, 64
              </a>
              ,{' '}
              <a href="tel:+919980850054" target="_blank" rel="noreferrer">
                +91 99808 50054
              </a>
            </span>
          </p>
        </div>
        <div className="contactDataTwo">
          <p className="marginTop">
            <span className="bold">Branch Office:</span>Simpliinsure.com (A VGIB
            Brand): Plot No. 32, Income Tax Colony, Ranapratap Nagar, Nagpur -
            440022, Maharashtra, India.
          </p>
          <p
            style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}
          >
            <FaPhone size="1rem" style={{ marginRight: '5px' }} />
            <span>
              <a href="tel:+918956516635" target="_blank" rel="noreferrer">
                +91 8956516635
              </a>
              ,{' '}
              <a href="tel:+917709940425 " target="_blank" rel="noreferrer">
                +91 7709940425
              </a>
            </span>
          </p>
        </div>
        <p className="marginTop bold">
          Disclaimer: Simpliinsure.com is an online portal managed by Virtual
          Galaxy Insurance Brokers Pvt. Ltd. | Registered as a Direct Broker
          (Life & General) | Registration No: 750 | Registration Code:
          IRDA/DB859/21 | Valid from 29/06/21 to 28/06/24 |{' '}
          <span className="colorRed">
            *Standard Terms and Conditions are applicable.
          </span>
        </p>
      </div>
      <div className="FooterSecFour">
        <p>
          <span className="bold">
            © Copyrights 2023. All Rights Reserved by Simpliinsure.com{' '}
          </span>
        </p>
      </div>
    </div>
  );
}

export default FooterNew