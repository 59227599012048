// import React, { useState, useEffect } from 'react';
// import './PolicyParticularDetails.css';

// const PolicyParticularDetails = () => {
//   const [formData, setFormData] = useState({
//     sNo: 1,
//     coverage: '',
//     extensions: '',
//     sumInsured: 0,
//     premRate: 0,
//     netOdPrem: 0,
//     locationRemarks: '',
//     gstTax: 0.0,
//   });

//   const [addedData, setAddedData] = useState([]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleAddData = () => {
//     const newData = [...addedData, { ...formData }];
//     setAddedData(newData);

//     // Increment the sNo for the next entry and reset part 1 fields
//     setFormData({
//       ...formData,
//       sNo: formData.sNo + 1,
//       coverage: '',
//       extensions: '',
//       sumInsured: 0,
//       premRate: 0,
//       netOdPrem: 0,
//       locationRemarks: '',
//       gstTax: 0.0,
//     });
//   };

//   const handleDelete = (indexToDelete) => {
//     const filteredData = addedData.filter(
//       (_, index) => index !== indexToDelete
//     );
//     setAddedData(filteredData);
//   };

//   const handleCalculateGrossPrem = () => {
//     const grossPremium =
//       parseFloat(formData.netPremium) +
//       parseFloat(formData.tpPremium) +
//       parseFloat(formData.rsaPremium) +
//       parseFloat(formData.brokPremium) +
//       parseFloat(formData.stampDuty);
//     setFormData({
//       ...formData,
//       grossPremium: grossPremium.toFixed(2),
//     });
//   };

//   return (
//     <div className="policy-particular-details">
//       <h2>Policy Particular and Premium Details</h2>
//       <div className="policy-particular-grid">
//         {/* Part 1 of the form */}
//         <div className="form-group">
//           <label htmlFor="sNo">S.No</label>
//           <input
//             type="text"
//             id="sNo"
//             name="sNo"
//             value={formData.sNo}
//             onChange={handleChange}
//             disabled
//           />
//         </div>

//         <div className="form-group">
//           <label htmlFor="coverage">Coverage/TP</label>
//           <select
//             id="coverage"
//             name="coverage"
//             value={formData.coverage}
//             onChange={handleChange}
//           >
//             <option value="">--Select Coverage--</option>
//             <option value="Extension">Extension</option>
//             <option value="Coverage">Coverage</option>
//           </select>
//         </div>

//         <div className="form-group required">
//           <label htmlFor="extensions">Extensions</label>
//           <select
//             id="extensions"
//             name="extensions"
//             value={formData.extensions}
//             onChange={handleChange}
//             required
//           >
//             <option value="">--Select Extension--</option>
//             <option value="Extension1">Extension 1</option>
//             <option value="Extension2">Extension 2</option>
//           </select>
//         </div>

//         <div className="form-group required">
//           <label htmlFor="sumInsured">Sum Insured</label>
//           <input
//             type="number"
//             id="sumInsured"
//             name="sumInsured"
//             value={formData.sumInsured}
//             onChange={handleChange}
//             required
//           />
//         </div>

//         <div className="form-group">
//           <label htmlFor="premRate">Prem. Rate</label>
//           <input
//             type="number"
//             id="premRate"
//             name="premRate"
//             value={formData.premRate}
//             onChange={handleChange}
//           />
//         </div>

//         <div className="form-group required">
//           <label htmlFor="netOdPrem">Net/OD Prem.</label>
//           <input
//             type="number"
//             id="netOdPrem"
//             name="netOdPrem"
//             value={formData.netOdPrem}
//             onChange={handleChange}
//             required
//           />
//         </div>

//         <div className="form-group">
//           <label htmlFor="locationRemarks">Location and Remarks</label>
//           <input
//             type="text"
//             id="locationRemarks"
//             name="locationRemarks"
//             value={formData.locationRemarks}
//             onChange={handleChange}
//           />
//         </div>

//         <div className="form-group">
//           <label htmlFor="gstTax">GST/Tax%</label>
//           <input
//             type="number"
//             id="gstTax"
//             name="gstTax"
//             value={formData.gstTax}
//             onChange={handleChange}
//           />
//         </div>

//         <div className="form-group">
//           <button type="button" className="add-btn" onClick={handleAddData}>
//             Add
//           </button>
//         </div>
//       </div>

//       {/* Display added data */}
//       <h3>Added Premium Details</h3>
//       <table style={{marginBottom:"20px"}}>
//         <thead>
//           <tr>
//             <th>Action</th>
//             <th>S.No</th>
//             <th>Coverage</th>
//             <th>Extensions</th>
//             <th>Sum Insured</th>
//             <th>Prem. Rate</th>
//             <th>Net/OD Prem</th>
//             <th>Location Remarks</th>
//             <th>GST/Tax%</th>
//           </tr>
//         </thead>
//         <tbody>
//           {addedData.map((data, index) => (
//             <tr key={index}>
//               <td>
//                 <button onClick={() => handleDelete(index)}>Delete</button>
//               </td>
//               <td>{data.sNo}</td>
//               <td>{data.coverage}</td>
//               <td>{data.extensions}</td>
//               <td>{data.sumInsured}</td>
//               <td>{data.premRate}</td>
//               <td>{data.netOdPrem}</td>
//               <td>{data.locationRemarks}</td>
//               <td>{data.gstTax}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {/* Separator */}
//      <hr style={{ marginTop: '20px', marginBottom: '20px' }} />


//       {/* Part 2 of the form */}
//       <div className="policy-particular-grid">
//         <div className="form-group">
//           <label htmlFor="multiInstPolicy">Multi-Inst. Policy</label>
//           <select
//             id="multiInstPolicy"
//             name="multiInstPolicy"
//             value={formData.multiInstPolicy}
//             onChange={handleChange}
//           >
//             <option value="No">No</option>
//             <option value="Yes">Yes</option>
//           </select>
//         </div>

//         <div className="form-group">
//           <label htmlFor="tpSumInsured">TP Sum Insured</label>
//           <input
//             type="number"
//             id="tpSumInsured"
//             name="tpSumInsured"
//             value={formData.tpSumInsured}
//             onChange={handleChange}
//           />
//         </div>

//         <div className="form-group">
//           <label htmlFor="tpPremium">TP Premium</label>
//           <input
//             type="number"
//             id="tpPremium"
//             name="tpPremium"
//             value={formData.tpPremium}
//             onChange={handleChange}
//           />
//         </div>

//         <div className="form-group">
//           <label htmlFor="rsaPremium">RSA Premium</label>
//           <input
//             type="number"
//             id="rsaPremium"
//             name="rsaPremium"
//             value={formData.rsaPremium}
//             onChange={handleChange}
//           />
//         </div>

//         <div className="form-group">
//           <label htmlFor="totalSumInsured">Total Sum Insured</label>
//           <input
//             type="number"
//             id="totalSumInsured"
//             name="totalSumInsured"
//             value={formData.totalSumInsured}
//             onChange={handleChange}
//           />
//         </div>

//         <div className="form-group">
//           <label htmlFor="brokPremium">Brok Premium</label>
//           <input
//             type="number"
//             id="brokPremium"
//             name="brokPremium"
//             value={formData.brokPremium}
//             onChange={handleChange}
//           />
//         </div>

//         <div className="form-group">
//           <label htmlFor="netPremium">Net Premium</label>
//           <input
//             type="number"
//             id="netPremium"
//             name="netPremium"
//             value={formData.netPremium}
//             onChange={handleChange}
//           />
//         </div>

//         <div className="form-group">
//           <label htmlFor="gstSTax">GST/S. Tax(%)</label>
//           <input
//             type="number"
//             id="gstSTax"
//             name="gstSTax"
//             value={formData.gstSTax}
//             onChange={handleChange}
//           />
//         </div>

//         <div className="form-group">
//           <label htmlFor="stampDuty">Stamp Duty</label>
//           <input
//             type="number"
//             id="stampDuty"
//             name="stampDuty"
//             value={formData.stampDuty}
//             onChange={handleChange}
//           />
//         </div>

//         <div className="form-group">
//           <label htmlFor="grossPremium">Gross Premium</label>
//           <input
//             type="number"
//             id="grossPremium"
//             name="grossPremium"
//             value={formData.grossPremium}
//             disabled
//           />
//         </div>

//         <div className="form-group">
//           <label htmlFor="oddDtd">ODD/DTD(%)</label>
//           <input
//             type="number"
//             id="oddDtd"
//             name="oddDtd"
//             value={formData.oddDtd}
//             onChange={handleChange}
//           />
//         </div>
//       </div>

//       <button
//         type="button"
//         onClick={handleCalculateGrossPrem}
//         className="calc-gross-prem-btn"
//       >
//         Calc Gross Prem
//       </button>
//     </div>
//   );
// };

// export default PolicyParticularDetails;

import React, { useState } from 'react';
import './PolicyParticularDetails.css';

const PolicyParticularDetails = ({
  handleAddData,
  addedData,
  handleDelete,
}) => {
  const [localFormData, setLocalFormData] = useState({
    sNo: 1,
    coverage: '',
    extensions: '',
    sumInsured: 0,
    premRate: 0,
    netOdPrem: 0,
    locationRemarks: '',
    gstTax: 0.0,
    multiInstPolicy: 'No',
    tpSumInsured: 0,
    tpPremium: 0,
    rsaPremium: 0,
    totalSumInsured: 0,
    brokPremium: 0,
    netPremium: 0,
    gstSTax: 0,
    stampDuty: 0,
    grossPremium: 0,
    oddDtd: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalFormData({
      ...localFormData,
      [name]: value,
    });
  };

  const handleAddLocalData = () => {
    handleAddData({ ...localFormData });
    setLocalFormData({
      sNo: localFormData.sNo + 1,
      coverage: '',
      extensions: '',
      sumInsured: 0,
      premRate: 0,
      netOdPrem: 0,
      locationRemarks: '',
      gstTax: 0.0,
      multiInstPolicy: 'No',
      tpSumInsured: 0,
      tpPremium: 0,
      rsaPremium: 0,
      totalSumInsured: 0,
      brokPremium: 0,
      netPremium: 0,
      gstSTax: 0,
      stampDuty: 0,
      grossPremium: 0,
      oddDtd: 0,
    });
  };

  const handleCalculateGrossPrem = () => {
    const grossPremium =
      Number.parseFloat(localFormData.netPremium) +
      Number.parseFloat(localFormData.tpPremium) +
      Number.parseFloat(localFormData.rsaPremium) +
      Number.parseFloat(localFormData.brokPremium) +
      Number.parseFloat(localFormData.stampDuty);
    setLocalFormData({
      ...localFormData,
      grossPremium: grossPremium.toFixed(2),
    });
  };

  return (
    <div className="policy-particular-details">
      <h2>Policy Particular and Premium Details</h2>

      {/* Part 1 of the form */}
      <div className="policy-particular-grid">
        <div className="form-group">
          <label htmlFor="sNo" className="policyParticularLabel">
            S.No
          </label>
          <input
            type="text"
            id="sNo"
            name="sNo"
            className="policyParticularInput"
            value={localFormData.sNo}
            onChange={handleChange}
            disabled
          />
        </div>

        <div className="form-group">
          <label htmlFor="coverage" className="policyParticularLabel">
            Coverage/TP
          </label>
          <select
            id="coverage"
            name="coverage"
            className="policyParticularSelect"
            value={localFormData.coverage}
            onChange={handleChange}
          >
            <option value="">--Select Coverage--</option>
            <option value="Extension">Extension</option>
            <option value="Coverage">Coverage</option>
          </select>
        </div>

        <div className="form-group required">
          <label htmlFor="extensions" className="policyParticularLabel">
            Extensions
          </label>
          <select
            id="extensions"
            name="extensions"
            className="policyParticularSelect"
            value={localFormData.extensions}
            onChange={handleChange}
          >
            <option value="">--Select Extension--</option>
            <option value="Extension1">Extension 1</option>
            <option value="Extension2">Extension 2</option>
          </select>
        </div>

        <div className="form-group required">
          <label htmlFor="sumInsured" className="policyParticularLabel">
            Sum Insured
          </label>
          <input
            type="number"
            id="sumInsured"
            name="sumInsured"
            className="policyParticularInput"
            value={localFormData.sumInsured}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="premRate" className="policyParticularLabel">
            Prem. Rate
          </label>
          <input
            type="number"
            id="premRate"
            name="premRate"
            className="policyParticularInput"
            value={localFormData.premRate}
            onChange={handleChange}
          />
        </div>

        <div className="form-group required">
          <label htmlFor="netOdPrem" className="policyParticularLabel">
            Net/OD Prem.
          </label>
          <input
            type="number"
            id="netOdPrem"
            name="netOdPrem"
            className="policyParticularInput"
            value={localFormData.netOdPrem}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="locationRemarks" className="policyParticularLabel">
            Location and Remarks
          </label>
          <input
            type="text"
            id="locationRemarks"
            name="locationRemarks"
            className="policyParticularInput"
            value={localFormData.locationRemarks}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="gstTax" className="policyParticularLabel">
            GST/Tax%
          </label>
          <input
            type="number"
            id="gstTax"
            name="gstTax"
            className="policyParticularInput"
            value={localFormData.gstTax}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <button
            type="button"
            className="add-btn"
            onClick={handleAddLocalData}
          >
            Add
          </button>
        </div>
      </div>

      {/* Display added data */}
      <h3>Added Premium Details</h3>
      <table style={{ marginBottom: '20px' }}>
        <thead>
          <tr>
            <th>Action</th>
            <th>S.No</th>
            <th>Coverage</th>
            <th>Extensions</th>
            <th>Sum Insured</th>
            <th>Prem. Rate</th>
            <th>Net/OD Prem</th>
            <th>Location Remarks</th>
            <th>GST/Tax%</th>
          </tr>
        </thead>
        <tbody>
          {addedData.map((data, index) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
<tr key={index}>
              <td>
                <button type='button' onClick={() => handleDelete(index)}>Delete</button>
              </td>
              <td>{data.sNo}</td>
              <td>{data.coverage}</td>
              <td>{data.extensions}</td>
              <td>{data.sumInsured}</td>
              <td>{data.premRate}</td>
              <td>{data.netOdPrem}</td>
              <td>{data.locationRemarks}</td>
              <td>{data.gstTax}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <hr style={{ marginTop: '20px', marginBottom: '20px' }} />

      {/* Part 2 of the form */}
      <div className="policy-particular-grid">
        <div className="form-group">
          <label htmlFor="multiInstPolicy" className="policyParticularLabel">
            Multi-Inst. Policy
          </label>
          <select
            id="multiInstPolicy"
            name="multiInstPolicy"
            className="policyParticularSelect"
            value={localFormData.multiInstPolicy}
            onChange={handleChange}
          >
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="tpSumInsured" className="policyParticularLabel">
            TP Sum Insured
          </label>
          <input
            type="number"
            id="tpSumInsured"
            name="tpSumInsured"
            className="policyParticularInput"
            value={localFormData.tpSumInsured}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="tpPremium" className="policyParticularLabel">
            TP Premium
          </label>
          <input
            type="number"
            id="tpPremium"
            name="tpPremium"
            className="policyParticularInput"
            value={localFormData.tpPremium}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="rsaPremium" className="policyParticularLabel">
            RSA Premium
          </label>
          <input
            type="number"
            id="rsaPremium"
            name="rsaPremium"
            className="policyParticularInput"
            value={localFormData.rsaPremium}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="totalSumInsured" className="policyParticularLabel">
            Total Sum Insured
          </label>
          <input
            type="number"
            id="totalSumInsured"
            name="totalSumInsured"
            className="policyParticularInput"
            value={localFormData.totalSumInsured}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="brokPremium" className="policyParticularLabel">
            Brok Premium
          </label>
          <input
            type="number"
            id="brokPremium"
            name="brokPremium"
            className="policyParticularInput"
            value={localFormData.brokPremium}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="netPremium" className="policyParticularLabel">
            Net Premium
          </label>
          <input
            type="number"
            id="netPremium"
            name="netPremium"
            className="policyParticularInput"
            value={localFormData.netPremium}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="gstSTax" className="policyParticularLabel">
            GST/S. Tax(%)
          </label>
          <input
            type="number"
            id="gstSTax"
            name="gstSTax"
            className="policyParticularInput"
            value={localFormData.gstSTax}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="stampDuty" className="policyParticularLabel">
            Stamp Duty
          </label>
          <input
            type="number"
            id="stampDuty"
            name="stampDuty"
            className="policyParticularInput"
            value={localFormData.stampDuty}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="grossPremium" className="policyParticularLabel">
            Gross Premium
          </label>
          <input
            type="number"
            id="grossPremium"
            name="grossPremium"
            className="policyParticularInput"
            value={localFormData.grossPremium}
            disabled
          />
        </div>

        <div className="form-group">
          <label htmlFor="oddDtd" className="policyParticularLabel">
            ODD/DTD(%)
          </label>
          <input
            type="number"
            id="oddDtd"
            name="oddDtd"
            className="policyParticularInput"
            value={localFormData.oddDtd}
            onChange={handleChange}
          />
        </div>
      </div>

      <button
        type="button"
        onClick={handleCalculateGrossPrem}
        className="calc-gross-prem-btn"
      >
        Calc Gross Prem
      </button>
    </div>
  );
};

export default PolicyParticularDetails;

