// import React, { useState } from 'react';
// import './servicingDetails.css';

// const ServicingDetailsSection = () => {
//   const [formData, setFormData] = useState({
//     posMispRef: 'Ref',
//     posMispRefCode: '',
//     posMispRefName: '',
//     cscPeCode: '',
//     cscPeName: '',
//     rmCe1Code: '',
//     rmCe1Name: '',
//     solicitorCe2Code: '',
//     solicitorCe2Name: '',
//     tcCode: '',
//     tcName: '',
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   return (
//     <div className="servicing-details-section">
//       <h2>Servicing Details</h2>
//       <div className="servicing-details-grid">
//         <div className="form-group required">
//           <label htmlFor="posMispRef">POS/MISP/Ref</label>
//           <select
//             id="posMispRef"
//             name="posMispRef"
//             value={formData.posMispRef}
//             onChange={handleChange}
//             required
//           >
//             <option value="Ref">Ref</option>
//             <option value="POS">POS</option>
//             <option value="MISP">MISP</option>
//           </select>
//         </div>

//         <div className="form-group">
//           <label htmlFor="posMispRefCode">POS/MISP/Ref Code</label>
//           <input
//             type="text"
//             id="posMispRefCode"
//             name="posMispRefCode"
//             value={formData.posMispRefCode}
//             onChange={handleChange}
//           />
//           <button className="info-btn">?</button>
//         </div>

//         <div className="form-group">
//           <label htmlFor="posMispRefName">POS/MISP/Ref Name</label>
//           <input
//             type="text"
//             id="posMispRefName"
//             name="posMispRefName"
//             value={formData.posMispRefName}
//             onChange={handleChange}
//             disabled
//           />
//         </div>

//         <div className="form-group required">
//           <label htmlFor="cscPeCode">CSC/PE Code</label>
//           <input
//             type="text"
//             id="cscPeCode"
//             name="cscPeCode"
//             value={formData.cscPeCode}
//             onChange={handleChange}
//             required
//           />
//           <button className="info-btn">?</button>
//         </div>

//         <div className="form-group">
//           <label htmlFor="cscPeName">CSC/PE Name</label>
//           <input
//             type="text"
//             id="cscPeName"
//             name="cscPeName"
//             value={formData.cscPeName}
//             onChange={handleChange}
//             disabled
//           />
//         </div>

//         <div className="form-group required">
//           <label htmlFor="rmCe1Code">RM/CE1 Code</label>
//           <input
//             type="text"
//             id="rmCe1Code"
//             name="rmCe1Code"
//             value={formData.rmCe1Code}
//             onChange={handleChange}
//             required
//           />
//           <button className="info-btn">?</button>
//         </div>

//         <div className="form-group">
//           <label htmlFor="rmCe1Name">RM/CE1 Name</label>
//           <input
//             type="text"
//             id="rmCe1Name"
//             name="rmCe1Name"
//             value={formData.rmCe1Name}
//             onChange={handleChange}
//             disabled
//           />
//         </div>

//         <div className="form-group required">
//           <label htmlFor="solicitorCe2Code">Solicitor/CE2 Code</label>
//           <input
//             type="text"
//             id="solicitorCe2Code"
//             name="solicitorCe2Code"
//             value={formData.solicitorCe2Code}
//             onChange={handleChange}
//             required
//           />
//           <button className="info-btn">?</button>
//         </div>

//         <div className="form-group">
//           <label htmlFor="solicitorCe2Name">Solicitor/CE2 Name</label>
//           <input
//             type="text"
//             id="solicitorCe2Name"
//             name="solicitorCe2Name"
//             value={formData.solicitorCe2Name}
//             onChange={handleChange}
//             disabled
//           />
//         </div>

//         <div className="form-group required">
//           <label htmlFor="tcCode">TC Code</label>
//           <input
//             type="text"
//             id="tcCode"
//             name="tcCode"
//             value={formData.tcCode}
//             onChange={handleChange}
//             required
//           />
//           <button className="info-btn">?</button>
//         </div>

//         <div className="form-group">
//           <label htmlFor="tcName">TC Name</label>
//           <input
//             type="text"
//             id="tcName"
//             name="tcName"
//             value={formData.tcName}
//             onChange={handleChange}
//             disabled
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ServicingDetailsSection;

import React from 'react';
import './servicingDetails.css';

const ServicingDetailsSection = ({ formData, handleChange }) => {
  return (
    <div className="servicing-details-section">
      <h2>Servicing Details</h2>
      <div className="servicing-details-grid">
        <div className="form-group required">
          <label htmlFor="posMispRef" className="servicingDetailsLabel">
            POS/MISP/Ref
          </label>
          <select
            id="posMispRef"
            name="posMispRef"
            className="servicingDetailsSelect"
            value={formData.posMispRef}
            onChange={handleChange}
            required
          >
            <option value="Ref">Ref</option>
            <option value="POS">POS</option>
            <option value="MISP">MISP</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="posMispRefCode" className="servicingDetailsLabel">
            POS/MISP/Ref Code
          </label>
          <input
            type="text"
            id="posMispRefCode"
            name="posMispRefCode"
            className="servicingDetailsInput"
            value={formData.posMispRefCode}
            onChange={handleChange}
          />
          <button className="info-btn servicingDetailsButton">?</button>
        </div>

        <div className="form-group">
          <label htmlFor="posMispRefName" className="servicingDetailsLabel">
            POS/MISP/Ref Name
          </label>
          <input
            type="text"
            id="posMispRefName"
            name="posMispRefName"
            className="servicingDetailsInput"
            value={formData.posMispRefName}
            onChange={handleChange}
            disabled
          />
        </div>

        <div className="form-group required">
          <label htmlFor="cscPeCode" className="servicingDetailsLabel">
            CSC/PE Code
          </label>
          <input
            type="text"
            id="cscPeCode"
            name="cscPeCode"
            className="servicingDetailsInput"
            value={formData.cscPeCode}
            onChange={handleChange}
            required
          />
          <button className="info-btn servicingDetailsButton">?</button>
        </div>

        <div className="form-group">
          <label htmlFor="cscPeName" className="servicingDetailsLabel">
            CSC/PE Name
          </label>
          <input
            type="text"
            id="cscPeName"
            name="cscPeName"
            className="servicingDetailsInput"
            value={formData.cscPeName}
            onChange={handleChange}
            disabled
          />
        </div>

        <div className="form-group required">
          <label htmlFor="rmCe1Code" className="servicingDetailsLabel">
            RM/CE1 Code
          </label>
          <input
            type="text"
            id="rmCe1Code"
            name="rmCe1Code"
            className="servicingDetailsInput"
            value={formData.rmCe1Code}
            onChange={handleChange}
            required
          />
          <button className="info-btn servicingDetailsButton">?</button>
        </div>

        <div className="form-group">
          <label htmlFor="rmCe1Name" className="servicingDetailsLabel">
            RM/CE1 Name
          </label>
          <input
            type="text"
            id="rmCe1Name"
            name="rmCe1Name"
            className="servicingDetailsInput"
            value={formData.rmCe1Name}
            onChange={handleChange}
            disabled
          />
        </div>

        <div className="form-group required">
          <label htmlFor="solicitorCe2Code" className="servicingDetailsLabel">
            Solicitor/CE2 Code
          </label>
          <input
            type="text"
            id="solicitorCe2Code"
            name="solicitorCe2Code"
            className="servicingDetailsInput"
            value={formData.solicitorCe2Code}
            onChange={handleChange}
            required
          />
          <button className="info-btn servicingDetailsButton">?</button>
        </div>

        <div className="form-group">
          <label htmlFor="solicitorCe2Name" className="servicingDetailsLabel">
            Solicitor/CE2 Name
          </label>
          <input
            type="text"
            id="solicitorCe2Name"
            name="solicitorCe2Name"
            className="servicingDetailsInput"
            value={formData.solicitorCe2Name}
            onChange={handleChange}
            disabled
          />
        </div>

        <div className="form-group required">
          <label htmlFor="tcCode" className="servicingDetailsLabel">
            TC Code
          </label>
          <input
            type="text"
            id="tcCode"
            name="tcCode"
            className="servicingDetailsInput"
            value={formData.tcCode}
            onChange={handleChange}
            required
          />
          <button className="info-btn servicingDetailsButton">?</button>
        </div>

        <div className="form-group">
          <label htmlFor="tcName" className="servicingDetailsLabel">
            TC Name
          </label>
          <input
            type="text"
            id="tcName"
            name="tcName"
            className="servicingDetailsInput"
            value={formData.tcName}
            onChange={handleChange}
            disabled
          />
        </div>
      </div>
    </div>
  );
};

export default ServicingDetailsSection;
